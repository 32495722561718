import LabTile from "../labs/LabTile";
import GlobalLessonData from "../API/LMS/LabData";
import GlobalUserData from "../API/LMS/UserData";
import {
    Grid,
    GridItem,
    Panel,
    Text
} from "@patternfly/react-core";
const DashboardLabTiles = ({ userid, labLimit }) => {
    const Lessons = GlobalLessonData();
    const UserData = GlobalUserData({ userid });

    return (
        <Panel className="mp-dashboard__ExercisesItems">
            <Grid hasGutter>
                {Lessons.slice(0, labLimit).map((lesson, index) => (
                    <GridItem span={6}>
                        <LabTile
                            UserData={UserData}
                            Lessons={Lessons}
                            lesson={lesson}

                        />
                    </GridItem>

                ))}
            </Grid>
        </Panel>
    )

}

export default DashboardLabTiles;