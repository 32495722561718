import React, { useState }  from 'react';
import {Button, DataList, DataListItem, DataListCell, DataListItemRow, DataListItemCells, DataListAction, Dropdown, DropdownList, DropdownItem, MenuToggle, MenuToggleElement} from '@patternfly/react-core';
import useFetchDirectory from './fetchDirectory';

const LabFiles = ( request ) => {
    // Get the path to the directory with traces and the file type
    const globalPathToTraces = request.path;
    const fileType = request.fileType;
    const AwsInstance = request.AwsInstance;

     // Reteiving the directory listings at the global path
    const {
        directoryListing: availablePaths,
        error: errorDirectory,
        isLoading: isLoadingDirectory,
    } = useFetchDirectory(
        globalPathToTraces ? globalPathToTraces : "null",
        fileType ? fileType : null,
        AwsInstance
    );
    
    if ( !availablePaths.length ) {
        // No valid items found, tell the user
        availablePaths.push( {
            name: 'No PCAP files found, if you have followed the previous steps correctly you should see files here. You will need to repeat the previous steps which include the capturing process.',
            type: 'Message'
        });
    }

    function downloadTrace ( path, id ) {
        let fileName = path;
        return fetch(process.env.REACT_APP_API_ENDPOINT + '/ec2/getServerFile?path=' + globalPathToTraces + "/" + encodeURIComponent(path))
        .then((res) => {
            if (!res.ok) {
            throw new Error('Network response was not ok');
            }
            return res.arrayBuffer();
        })
        .then((res) => {
            handleResponse(res, fileName)
        })
        .catch((error) => {
            console.error('Fetch error:', error);
        });
    }
    
    function handleResponse ( res, id ) {
        const element = document.createElement("a");
        const dataToDownload = res;
        const file = new Blob([dataToDownload], {
          type: "application/vnd.tcpdump.pcap",
        });
        element.href = URL.createObjectURL(file);
        element.download = id.replace(/\.pcap$/, '') + `.pcap`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    
    
    return (
        <DataList>
            {availablePaths.map(( fileData ) => (
                <DataListItem aria-labelledby="single-action-item1">
                    <DataListItemRow>
                        <DataListItemCells
                            dataListCells={[
                            <DataListCell key="primary content">
                                <span id="single-action-item1">
                                    {fileData.name}
                                </span>
                            </DataListCell>
                            ]}
                        />
                        { fileData.type == 'file' &&  (
                            <DataListAction
                                aria-labelledby="single-action-item1 single-action-action1"
                                id="single-action-action1"
                                aria-label="Actions"
                                >
                                <a href=""/>
                                <Button
                                    onClick={() => { downloadTrace(fileData.name, 1) }}
                                    variant="primary"
                                    key="delete-action"
                                    >
                                    Download
                                </Button>
                                
                            </DataListAction>
                        )}
                    </DataListItemRow>
                </DataListItem>
            ))}
        </DataList>
    );
}

//<TraceFile
//file={filename.name}
///>
//   return <React.Fragment>
//       <DataList aria-label="single action data list example ">
//         {!isDeleted && <DataListItem aria-labelledby="single-action-item1">
//             <DataListItemRow>
//               <DataListItemCells dataListCells={[<DataListCell key="primary content">
//                     <span id="single-action-item1">Single actionable Primary content</span>
//                   </DataListCell>, <DataListCell key="secondary content">Single actionable Secondary content</DataListCell>]} />
//               <DataListAction aria-labelledby="single-action-item1 single-action-action1" id="single-action-action1" aria-label="Actions">
//                 <Button onClick={() => {
    
//       setIsDeleted(true);
    
//   }} variant="primary" key="delete-action">
//                   Delete
//                 </Button>
//               </DataListAction>
//             </DataListItemRow>
//           </DataListItem>}
//         <DataListItem aria-labelledby="multi-actions-item1">
//           <DataListItemRow>
//             <DataListItemCells dataListCells={[<DataListCell key="primary content">
//                   <span id="multi-actions-item1">Multi actions Primary content</span>
//                 </DataListCell>, <DataListCell key="secondary content">Multi actions Secondary content</DataListCell>]} />
//             <DataListAction aria-labelledby="multi-actions-item1 multi-actions-action1" id="multi-actions-action1" aria-label="Actions" isPlainButtonAction>
//               <Dropdown popperProps={{
//     position: 'right'
//   }} onSelect={onSelect} toggle={toggleRef => <MenuToggle ref={toggleRef} isExpanded={isOpen} onClick={onToggle} variant="plain" aria-label="Data list with actions example kebab toggle">
//                     <EllipsisVIcon aria-hidden="true" />
//                   </MenuToggle>} isOpen={isOpen} onOpenChange={isOpen => setIsOpen(isOpen)}>
//                 <DropdownList>
//                   <DropdownItem key="action">Action</DropdownItem>
//                   {{}}
//                   <DropdownItem key="link" to="#" onClick={event => event.preventDefault()}>
//                     Link
//                   </DropdownItem>
//                   <DropdownItem key="disabled action" isDisabled>
//                     Disabled Action
//                   </DropdownItem>
//                   <DropdownItem key="disabled link" isDisabled to="#" onClick={event => event.preventDefault()}>
//                     Disabled Link
//                   </DropdownItem>
//                 </DropdownList>
//               </Dropdown>
//             </DataListAction>
//           </DataListItemRow>
//         </DataListItem>
//       </DataList>
//     </React.Fragment>;
// };

export default LabFiles;