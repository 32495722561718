import { Button, Modal, ModalVariant, Stack, StackItem, Text } from '@patternfly/react-core';
import React, { useState, useEffect } from 'react';
import extendInstanceState from '../../API/AWS/ExtendInstance';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faSpinner } from '@fortawesome/pro-solid-svg-icons';

const AWSExtendModal = ( { AwsInstance, setAwsInstance } ) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const instanceTerminationBuffer = 300000; // 5 minutes in milliseconds
    useEffect(() => {
        // Set the time when you want the popup to appear (e.g., 10 seconds from now)

        const timeToShowPopup = new Date( AwsInstance.eol ) - instanceTerminationBuffer;

        // Calculate the delay until the popup should appear
        const delay = timeToShowPopup - new Date().getTime();

        // Wait until the specified time and then show the popup
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, delay);

        // Cleanup function to clear the timer when the component unmounts or rerenders
        return () => clearTimeout(timer);
    }, []); // Empty dependency array ensures the effect runs only once

    const TimestampFormatter = ({ timestamp }) => {
        const formattedTime = new Date(timestamp).toLocaleTimeString(undefined, {
          hour: 'numeric',
          minute: 'numeric',
        });
      
        return <span>{formattedTime}</span>;
      };

    const extendAWSInstance = async () => {
        setLoading(true);

        try {
            const update = await extendInstanceState( AwsInstance?.id, AwsInstance?.name );

            if ( update.AWSInstance.success ) {
                setAwsInstance(prevState => ({
                    ...prevState,
                    eol: update.AWSInstance.eol
                }));

                setShowPopup( false );
            } else {
                navigate( '/', {
                    state: {
                        'hadTimedOut': true
                    }
                });
            }
        } finally {
            setLoading( false );
        }
    }

    const handleModalToggle = () => {
        setShowPopup(false);
    };
    
    return (
        <Modal
            variant={ModalVariant.small}
            title="Extend Amazon AWS Instance"
            isOpen={showPopup && AwsInstance.status}
            className='AWSServiceextend'
            onClose={handleModalToggle}
        >
            <Text>This instance will terminate at <TimestampFormatter timestamp={ AwsInstance?.eol }/>. Would you like to continue working?</Text>
            <Stack>
                <StackItem>
                    <Button onClick={() => extendAWSInstance()} disabled={loading}>
                        { loading ? ( <Text> <FontAwesomeIcon icon={faSpinner} spin="true" /> Extend AWS Instance</Text> ) : ( <Text> <FontAwesomeIcon icon={faServer} /> Extend AWS Instance</Text>) }
                    </Button>
                </StackItem>
            </Stack>
        </Modal>
    );
}

export default AWSExtendModal;
