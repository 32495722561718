const pushAPIData = async ({ userid, UserData }) => {
  try {
    if (!userid || !UserData) {
      return false;
    }

    const endpointurl = process.env.REACT_APP_URL;
    const wstoken = process.env.REACT_APP_WSTOKEN;
    const moodlewsrestformat = process.env.REACT_APP_API_FORMAT;
    const wsfunction = 'local_mpirical_lab_save_user';
    const user = userid;
    const userdata = JSON.stringify(UserData);

    const url = `${endpointurl}/webservice/rest/server.php`;
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        wstoken,
        wsfunction,
        moodlewsrestformat,
        userid: user,
        data: userdata,
      }),
    };

    const response = await fetch(url, params);
    const data = await response.json();

    // return data;
  } catch (error) {
    console.error('Error fetching labs data:', error);
    throw error;
  }
};
  
  export default pushAPIData;