import React, { useState, useEffect } from 'react';

import NetXLayout from "./NetXLayout";
import NetTMoLayout from "./NetTMoLayout";
const RootLayout = ({ content }) => {
    const [minHeights, setMinHeights] = useState({});

    useEffect(() => {
        const handleDynamicContent = () => {
            const elements = document.getElementsByClassName("mp-minheight");

            Array.from(elements).forEach((element) => {
                const targetHeight = parseInt(element.getAttribute("data-targetHeight"), 10);

                // Toggle display based on the condition
                if (element.offsetHeight < targetHeight) {
                    element.style.display = "none";

                    // Check if the window height has been stored
                    if (!minHeights[element.id]) {
                        // If not, store the window height
                        minHeights[element.id] = window.innerHeight;

                        // Update the minHeights state
                        setMinHeights({ ...minHeights });
                    }
                } else {
                    element.style.display = "";
                }

                // Restore display if the window height is larger than the stored height
                if (window.innerHeight > minHeights[element.id]) {
                    element.style.display = "";
                }
            });
        };

        // Initial load
        handleDynamicContent();

        // Resize event listener
        const handleResize = () => {
            handleDynamicContent();
        };

        // Add resize event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array for initial load only



if (process.env.REACT_APP_ENV_CSS === 'tmo') {
    return <NetTMoLayout content={content} />
} else {
    return <NetXLayout content={content} />
}
};

export default RootLayout;