import React from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import io from "socket.io-client";
import _debounce from "lodash/debounce";

const SOCKET_EVENT_EXCHANGE_COMMANDS = "exchangeCommands";
const SOCKET_EVENT_COMMANDS = "commands";

class LabTerminal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialCommandTrigger: props.initialCommandTrigger || null,
            initialCommand: props.initialCommand || null,
        };

        this.container = props.container;
        this.term = null;
        this.fitAddon = null;
        this.shellSocket = null;

        this.debouncedEmitCommands = _debounce(this.emitCommands, 30); // Debounce keypresses
    }

    componentDidMount() {

        this.create();
    }

    componentWillUnmount() {
        this.destroy();
    }

    create(initialCommandTrigger = null, initialCommand, AWSHost) {
        const terminalContainer = document.getElementById(this.container);
        terminalContainer.innerHTML = "";
// Create a new div element
const childDiv = document.createElement("div");
childDiv.id = this.container + '-child';

// Append the new div to terminalContainer
terminalContainer.appendChild(childDiv);


        this.term = new Terminal({
            cursorBlink: true,
            fontSize: 15,
            rows: 16,
            cols: 80,
            wrap: false,
            showFull: true
        });
        
        this.term.open(childDiv);

        this.shellSocket = io.connect(`${process.env.REACT_APP_API_ENDPOINT}`, {
            query: { AWSHost: this.props.AWSHost.address },
        });

        this.shellSocket.on("connect", () => {
            console.log("Backend successfully connected");
        });

        this.shellSocket.emit("exchangeCommands");

        this.shellSocket.on("error", (error) => {
            console.error("Socket error:", error);
        });


        this.term.onData((ev) => {
            this.sendKeystrokes(ev);
        });

        this.shellSocket.on("data", (data) => {
            this.term.write(data);
        });

        return true;
    }

    sendCommand() {
        this.shellSocket.emit(SOCKET_EVENT_COMMANDS, "./cleanup.sh\n");
    }

    destroy() {
        if (this.term) {
            this.term.dispose();
        }

        if (this.shellSocket) {
            this.shellSocket.disconnect();
        }
    }

    render() {
        return <div style={{ width: '100%', height: '100%', backgroundColor: 'crimson' }} id={`${this.container}-child`}></div>;
    }

    emitCommands = (ev) => {
        this.shellSocket.emit("commands", ev);
        //this.fitAddon.fit();
    };

    sendKeystrokes(keystrokes) {
        this.shellSocket.emit("keystrokes", keystrokes);
        //this.fitAddon.fit();
    }
}

export default LabTerminal;
