const terminateInstance = async ( instanceId ) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/ec2/terminateEC2Instance`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ instanceId }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error.message);
    }
};

export default terminateInstance;