import React, { useState, useEffect } from 'react';
import {
    Bullseye,
    Title,
    Button,
    Divider,
    Panel
} from '@patternfly/react-core';
const LoginPage = () => {
  const [cookieContents, setCookieContents] = useState('');

  useEffect(() => {
    // Get and display the cookie contents
    const cookies = document.cookie;
    setCookieContents(cookies);
  }, []);

  const setCookie = () => {
    // Set the cookie here
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 90 * 60 * 1000); // 90 minutes in milliseconds

    const cookieOptions = {
        expires: expirationDate.toUTCString(),
    };
    const userId = 123;

    // Construct the cookie string with userid
    const cookieString = `MoodleSession=cookieValue; userid=${userId}`;
    
    // Add additional options if any
    const optionsString = Object.entries(cookieOptions)
      .map(([key, value]) => `${key}=${value}`)
      .join('; ');

    // Combine the cookie and options
    document.cookie = `${cookieString}; ${optionsString}`;

    // Redirect to the dashboard after setting the cookie
    // Replace '/dashboard' with the actual path you want to redirect to
    window.location.href = '/#/dashboard';
};

  const setSecureCookie = () => {
    // Set the cookie with an expiration time of 10 minutes from now
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 10 * 60 * 1000); // 10 minutes in milliseconds
  
    const cookieOptions = {
      expires: expirationDate.toUTCString(),
      path: '/', // Specify the path for which the cookie is valid
      secure: true, // Set the secure flag to ensure the cookie is sent over HTTPS only
      httpOnly: true, // Set the httpOnly flag to prevent access from JavaScript
      // Add the samesite flag to control when cookies are sent with cross-origin requests
      sameSite: 'Strict', // 'Strict', 'Lax', or 'None'
    };
  
    document.cookie = `MoodleSession=cookieValue; ${Object.entries(cookieOptions)
      .map(([key, value]) => `${key}=${value}`)
      .join('; ')}`;
    
    // Redirect to the dashboard after setting the cookie
    // Replace '/dashboard' with the actual path you want to redirect to
    window.location.href = '/#/dashboard';
  };

  return (
    <Bullseye>
      <Panel>
        <Title headingLevel='h1'>Your Session has expired</Title>
        {/* Button to set the cookie */}
        <Button onClick={setCookie}>Set Cookie and Login</Button>
        <div><textarea value={cookieContents} readOnly rows={10} cols={50} /></div>
      </Panel>
    </Bullseye>
  );
};

export default LoginPage;