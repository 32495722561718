import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Panel,
    Button,
    ButtonType,
    Title,
    Divider,
    Text,
    Brand,
    Form,
    TextInput,
    ActionGroup,
    FormGroup,
    Radio,
    List,
    ListItem,
    Checkbox,
    Alert,
    CodeBlock,
    CodeBlockAction,
    ClipboardCopyButton,
    CodeBlockCode
} from '@patternfly/react-core';
import { useRoot } from "../RootContext";
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';
import LessonConsole from './items/console';
import SingleConsole from './items/singleConsole';
import LabTerminal from './classes/terminal.class';
import LabFiles from './items/fileLister/fileLister';

const LessonWizardStep = ({ labID, stepData, UserData, setUserData, findStatusByLabAndQuizId, markSectionComplete, sendAWSCommand, AwsInstance, createAWSInstance }) => {
    const [quizAttempt, setQuizAttempt] = React.useState(0);
    const [quizPassed, setQuizPassed] = React.useState(false);
    const [copied, setCopied] = useState(false);

    const findValueByLabAndQuestionId = (data, labId, questionId) => {
        // Find the lab item with the matching labId
        // Iterate through each labid object
        for (const lab of data.data) {
            // Check if labid is 1 in the current object
            if (lab.labid === labId) {
                // Search for the string in the questions array
                const question = lab.questions?.find(q => q.id === questionId);

                // Set the input value based on the search result
                const desiredValue = question ? question.value : '';

                // Set the input value
                return desiredValue;

                // Break out of the loop if a matching labid is found
                break;
            }
        }

        // Return null or a default value if not found
        return ''; // or return a default value of your choice
    };

    const setQuestionState = (labID, questionID, questionValue) => {
        setUserData((prevUserData) => {
            const labIndex = prevUserData.data.findIndex(
                (labDataItem) => labDataItem.labid === labID
            );

            if (labIndex === -1) {
                // If labID doesn't exist, add a new lab object
                const newLabDataItem = {
                    labid: labID,
                    questions: [{ id: questionID, value: questionValue }],
                };

                return {
                    ...prevUserData,
                    data: [...prevUserData.data, newLabDataItem],
                };
            }

            const updatedUserData = {
                ...prevUserData,
                data: prevUserData?.data?.map((labDataItem) => {
                    if (labDataItem.labid === labID) {
                        // Check if the question with questionID exists
                        const questionIndex = labDataItem.questions?.findIndex(
                            (question) => question.id === questionID
                        );

                        // If question with questionID doesn't exist, add a new question
                        if (questionIndex === -1) {
                            return {
                                ...labDataItem,
                                questions: [
                                    ...labDataItem.questions,
                                    { id: questionID, value: questionValue },
                                ],
                            };
                        }

                        // Update the existing question with a new value
                        return {
                            ...labDataItem,
                            questions: labDataItem?.questions?.map((question, index) => {
                                if (index === questionIndex) {
                                    return {
                                        ...question,
                                        value: questionValue, // Convert string to boolean
                                        // You can update other properties as needed
                                    };
                                }
                                return question;
                            }),
                        };
                    }
                    return labDataItem;
                }),
            };

            return updatedUserData;
        });
    };

    const setQuizState = (labID, quizID, quizValue) => {
        setUserData((prevUserData) => {
            const labIndex = prevUserData.data.findIndex(
                (labDataItem) => labDataItem.labid === labID
            );

            if (labIndex === -1) {
                // If labID doesn't exist, add a new lab object with an empty 'quizzes' array
                return {
                    ...prevUserData,
                    data: [
                        ...prevUserData.data,
                        {
                            labid: labID,
                            quizzes: [{ id: quizID, value: quizValue }],
                        },
                    ],
                };
            }

            return {
                ...prevUserData,
                data: prevUserData.data.map((labDataItem) => {
                    if (labDataItem.labid === labID) {
                        // Ensure 'quizzes' is initialized as an array
                        const currentQuizzes = labDataItem.quizzes || [];

                        // Check if the quiz with quizID exists
                        const quizIndex = currentQuizzes.findIndex(
                            (quiz) => quiz.id === quizID
                        );

                        // If quiz with quizID doesn't exist, add a new quiz
                        if (quizIndex === -1) {
                            return {
                                ...labDataItem,
                                quizzes: [...currentQuizzes, { id: quizID, value: quizValue }],
                            };
                        }

                        // Update the existing quiz with a new value
                        return {
                            ...labDataItem,
                            quizzes: currentQuizzes.map((quiz) =>
                                quiz.id === quizID ? { ...quiz, value: quizValue } : quiz
                            ),
                        };
                    }

                    return labDataItem;
                }),
            };
        });
    };

    const HandleRadioInput = (contentItem, target) => {
        contentItem.options.map((question, index) => {
            if (question.id === target.id) {
                setQuestionState(labID, question.id, true);
            } else {
                setQuestionState(labID, question.id, false);
            }

            return question;
        });
        return null;
    }

    const HandlCheckboxInput = (contentItem, target) => {

        if (contentItem.id === target.id) {
            setQuestionState(labID, contentItem.id, target.checked);
        }
        return null;
    }

    const ExerciseQuiz = ({ quizType, contentItem, contentIndex, hasPassed }) => {
        setQuizPassed(hasPassed);
        const HandleQuizSubmit = () => {

            contentItem.options.map((question) => {
                if (findValueByLabAndQuestionId(UserData, labID, question.id) === contentItem.quizanswer.trim().toLowerCase()) {
                    setQuizPassed(true);
                    setQuizState(labID, contentItem.id, true);
                    setQuizAttempt(0);
                } else {
                    setQuizAttempt((prevAttempt) => prevAttempt + 1);
                    setQuizPassed(false);
                    setQuizState(labID, contentItem.id, false);
                }
                return null;
            })
        }

        const HandleRadioQuizSubmit = () => {
            let answerStatus = false;

            contentItem.options.map((question) => {
                if (question.id === contentItem.quizanswer && findValueByLabAndQuestionId(UserData, labID, question.id) === true) {
                    answerStatus = true;
                }

                return null;
            });

            if (!answerStatus) {
                //incorrect
                setQuizAttempt((prevAttempt) => prevAttempt + 1);
            } else {
                //correct
                setQuizAttempt(0);
                setQuizState(labID, contentItem.id, answerStatus);
                //markSectionComplete()
                setQuizPassed(answerStatus);
            }

        }

        const HandleMultiQuizSubmit = () => {
            // If you need the overall answerStatus, you can still calculate it using every:
            const overallAnswerStatus = contentItem.quizanswers.every((answer) => {
                return (
                    contentItem.options.some((question) => {
                        return (
                            question.id === answer &&
                            findValueByLabAndQuestionId(UserData, labID, question.id) === true
                        );
                    }) &&
                    contentItem.options.every((question) => {
                        return (
                            contentItem.quizanswers.includes(question.id) ||
                            findValueByLabAndQuestionId(UserData, labID, question.id) !== true
                        );
                    })
                );
            });

            if (!overallAnswerStatus) {
                //correct
                setQuizAttempt((prevAttempt) => prevAttempt + 1);
            } else {
                //incorrect
                setQuizAttempt(0);
                setQuizState(labID, contentItem.id, overallAnswerStatus);
                setQuizPassed(overallAnswerStatus);
            }

        }

        return (
            <Form className='mp-quizPanelQuestion'>
                <Panel className='mp-quizPanelQuestionContainer'>
                    <Panel className='mp-quizPanelQuestionHeader'><Text>Question</Text></Panel>
                    <Panel className='mp-quizPanelQuestionContent'>
                        {quizType === 'input' &&
                            contentItem.options.map((question, index) => (
                                <ExerciseQuizTextQuestion contentItem={contentItem} question={question} />
                            ))
                        }

                        {quizType === 'radio' && (
                            <ExerciseQuizRadioQuestion contentItem={contentItem} />
                        )}

                        {quizType === 'multi' && (
                            <ExerciseQuizMultiQuestion contentItem={contentItem} />
                        )}
                    </Panel>
                </Panel>


                {quizAttempt === 1 && (
                    <Alert variant="danger" isInline title="Incorrect, please try again" />
                )}

                {quizAttempt > 1 && (
                    <Alert variant="warning" isInline title={contentItem.hint} />
                )}

                {quizPassed && (
                    <Alert variant="success" isInline title="Correct" />
                )}

                <ActionGroup>
                    <Button
                        key={`button${contentItem.id}`}
                        type={ButtonType.submit}
                        onClick={(e) => {
                            e.preventDefault();
                            if (quizType === "input") {
                                HandleQuizSubmit();
                            } else if (quizType === "radio") {
                                HandleRadioQuizSubmit();
                            } else if (quizType === "multi") {
                                HandleMultiQuizSubmit();
                            }

                        }}
                        isAriaDisabled={quizPassed}
                    >
                        {quizPassed ? 'Answer Submitted' : 'Check Answer'}
                    </Button>
                </ActionGroup>
            </Form>
        );
    };

    const ExerciseQuizTextQuestion = ({ contentItem, question }) => {
        return <FormGroup className='mp-quizPanelQuestionTitle' label={contentItem.data} type="number" fieldId="age">
            <TextInput
                key={question.id}
                isRequired
                isDisabled={quizPassed}
                type="text"
                id="simple-form-name-01"
                name="simple-form-name-01"
                aria-describedby="simple-form-name-01-helper"
                value={findValueByLabAndQuestionId(UserData, labID, question.id)}
                onChange={(e) => setQuestionState(labID, question.id, e.target.value)}
                className='mp-quizPanelQuestionText'
            />
        </FormGroup>;
    };

    const ExerciseQuizRadioQuestion = ({ contentItem }) => {
        return <FormGroup className='mp-quizPanelQuestionTitle' role="radiogroup" isStack label={contentItem.data} type="number" fieldId="age">
            {
                contentItem.options.map((question, index) => (
                    <Radio
                        key={question.id}
                        id={question.id}
                        isDisabled={quizPassed}
                        name={question.id}
                        label={question.data}
                        isChecked={findValueByLabAndQuestionId(UserData, labID, question.id)}
                        onChange={(e) => { HandleRadioInput(contentItem, e.target) }}
                        className='mp-quizPanelQuestionText'
                    />
                ))
            }
        </FormGroup>;
    }

    const ExerciseQuizMultiQuestion = ({ contentItem }) => {
        return <FormGroup className='mp-quizPanelQuestionTitle' role="radiogroup" isStack label={contentItem.data} type="number" fieldId="age">
            {
                contentItem.options.map((question, index) => (
                    <Checkbox
                        key={question.id}
                        id={question.id}
                        isDisabled={quizPassed}
                        name={question.id}
                        label={question.data}
                        isChecked={findValueByLabAndQuestionId(UserData, labID, question.id)}
                        //onChange={(e) => {console.log("radio", e.target.checked)}}
                        onChange={(e) => setQuestionState(labID, question.id, e.target.checked)}
                        className='mp-quizPanelQuestionText'
                    />
                ))
            }
        </FormGroup>;
    }

    const renderContentItem = (contentItem, contentIndex) => {
        if (contentItem.type === 'title') {
            return <Title headingLevel="h1" className='mp-quizTitle' key={contentIndex}>{contentItem.data}</Title>;
        }
        if (contentItem.type === 'text') {
            return <Text key={contentIndex} className='mp-quizText'>{contentItem.data}</Text>;
        }
        if (contentItem.type === 'textstyle') {
            return <Text key={contentIndex} className='mp-quizText' dangerouslySetInnerHTML={{ __html: contentItem.data }} />;
        }
        if (contentItem.type === 'divider') {
            return <Divider key={contentIndex} className='mp-quizDivider' />;
        }
        if (contentItem.type === 'image') {
            return <Panel className='mp-quizPanel mp-quizPanelImage'>
                <Brand
                    key={contentIndex}
                    src={contentItem.data}
                    className={contentItem.size ? `mp-quizImage mp-quizImageWithSize${contentItem.size}` : 'mp-quizImage'}
                />
                <Text component="em" key={contentIndex} className='mp-quizAnnotation'>{contentItem.title}</Text>
            </Panel>
        }
        if (contentItem.type === 'quiz') {
            return <ExerciseQuiz
                quizType={contentItem.quiztype}
                contentItem={contentItem}
                contentIndex={contentIndex}
                hasPassed={findStatusByLabAndQuizId(UserData, labID, contentItem.id)}
            />
        }
        if (contentItem.type === 'list') {
            return <Panel className='mp-quizPanel'>
                <Text key={contentIndex} className='mp-quizText'>{contentItem.data}</Text>
                <List>
                    {contentItem.items.map((listItem, listIndex) => (
                        <>
                            {listItem.text && (
                                <ListItem key={listIndex}>{listItem.text}</ListItem>
                            )}
                            {listItem.textstyle && (
                                <ListItem key={listIndex}>
                                    <Text key={listIndex} dangerouslySetInnerHTML={{ __html: listItem.textstyle }} />
                                </ListItem>
                            )}
                            {listItem.children?.map((subListItem, subListIndex) => (
                                <List>
                                    {subListItem.text && (
                                        <ListItem key={subListIndex}>{subListItem.text}</ListItem>
                                    )}
                                    {subListItem.textstyle && (
                                        <ListItem key={subListIndex}>
                                            <Text key={subListIndex} dangerouslySetInnerHTML={{ __html: subListItem.textstyle }} />
                                        </ListItem>
                                    )}
                                </List>
                            ))}
                        </>
                    ))}
                </List>
            </Panel>
        }

        if (contentItem.type === 'liststyle') {
            return <Panel className='mp-quizPanel'>
                <Text key={contentIndex} className='mp-quizText' dangerouslySetInnerHTML={{ __html: contentItem.data }} />
                <List>
                    {contentItem.items.map((listItem, listIndex) => (
                        <>
                            {listItem.text && (
                                <ListItem key={listIndex}>
                                    <Text key={listIndex} dangerouslySetInnerHTML={{ __html: listItem.text }} />
                                </ListItem>
                            )}
                            {listItem.children?.map((subListItem, subListIndex) => (
                                <List>
                                    {subListItem.text && (
                                        <ListItem key={subListIndex}>
                                            <Text key={subListIndex} dangerouslySetInnerHTML={{ __html: subListItem.text }} />
                                        </ListItem>
                                    )}
                                </List>
                            ))}
                        </>
                    ))}
                </List>
            </Panel>
        }

        if (contentItem.type === 'link') {
            return <Panel className='mp-quizPanel mp-quizPanelLink'>
                <Button
                    component="a"
                    href={contentItem.href}
                    className='mp-quizButton'
                    target='_blank'
                >
                    {contentItem.title}
                </Button>
            </Panel>
        }

        if (contentItem.type === "awsconsole") {
            return (
                <Panel>
                    {AwsInstance?.status ? (
                        <SingleConsole
                            terminalid={contentItem.id}
                            AwsInstance={AwsInstance}
                        />
                    ) : null}
                </Panel>
            )
        }

        if (contentItem.type === "codeblock") {
            const clipboardCopyFunc = (event, text) => {
                navigator.clipboard.writeText(text.toString());
            };

            const onClick = (event, text) => {
                clipboardCopyFunc(event, text);
                setCopied(true);
            };

            const code = `${contentItem.data}`;

            const actions = <CodeBlockAction>
                <ClipboardCopyButton id="basic-copy-button" textId="code-content" aria-label="Copy to clipboard" onClick={e => onClick(e, code)} exitDelay={copied ? 1500 : 600} maxWidth="110px" variant="plain" onTooltipHidden={() => setCopied(false)}>
                    {copied ? 'Successfully copied to clipboard!' : 'Copy to clipboard'}
                </ClipboardCopyButton>
            </CodeBlockAction>;
            return <CodeBlock actions={actions}>
                <CodeBlockCode id="code-content">{code}</CodeBlockCode>
            </CodeBlock>;
        }

        if (contentItem.type === "files") {
            return <LabFiles
                path={contentItem.path}
                fileType={contentItem.filetype}
                fileFilter="*"
                AwsInstance={AwsInstance}
            />
        }

        if (contentItem.type === 'vimeo') {
            return <div className='mp-quizVimeo' style={{ textAlign: 'center', height: '0', width: '100%', position: 'relative', margin: '0 auto' }}>
                <iframe
                    title="Vimeo Video"
                    src={contentItem.data}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: '0', left: '50%', width: '100%', height: '100%', transform: 'translateX(-50%)' }}
                ></iframe>
            </div>
        }

        if (contentItem.type === 'headerimage') {
            return <Brand
                key={contentIndex}
                src={contentItem.data}
                className='mp-heroImage'
            />
        }
        // Add more conditions based on contentItem.type if needed
        return null; // Or handle other content types
    };

    return (
        stepData.content?.map(
            (contentItem, contentIndex) => renderContentItem(contentItem, contentIndex)
        )
    )

};

export default LessonWizardStep;