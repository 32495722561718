import React, { useEffect, useState } from "react";
import {
    Panel,
    PanelMain,
    PanelMainBody,
    Grid,
    GridItem,
    Card,
    CardHeader,
    CardBody,
    Title,
    Text,
    Progress,
    Badge,
    Skeleton
} from "@patternfly/react-core";
import DashboardPopup from "../dashboard/DashboardPopup";
import { useLocation } from "react-router-dom";
import LabTile from "./LabTile";
import GlobalUserData from "../API/LMS/UserData";
import GlobalLessonData from "../API/LMS/LabData";

const LabListing = ({ userid, labLimit }) => {
    const Lessons = GlobalLessonData();
    const UserData = GlobalUserData({ userid });

    const location = useLocation();

    return (
        <Panel className='mp-labsListing' style={{ backgroundColor: 'transparent' }}>
            <PanelMain>
                <PanelMainBody>
                    <Grid
                        hasGutter
                    >
                        {Lessons.map((lesson, index) => (
                            <GridItem key={lesson.lessonID}
                                sm={12}
                                md={12}
                                lg={6}
                                xl={6}
                                xl2={4}
                                span={12}
                            >
                                {UserData ? (
                                    <LabTile
                                        UserData={UserData}
                                        Lessons={Lessons}
                                        lesson={lesson}

                                    />
                                ) : (
                                    <Skeleton width="100%" height="200px" count={3} />
                                )}
                            </GridItem>
                        ))}
                    </Grid>
                </PanelMainBody>
            </PanelMain>
            {location?.state?.hadTimedOut ? (
                <DashboardPopup ></DashboardPopup>
            ) : null}

        </Panel>
    )
}

export default LabListing;