import React, { useState, useEffect } from 'react';
import {
    Panel,
    Button,
    Modal,
    ModalVariant,
    Text,
    Stack,
    StackItem
} from '@patternfly/react-core';
import checkInstanceState from '../../API/AWS/checkState';
import createInstanceState from '../../API/AWS/CreateInstanceState';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader, faServer, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import AWSStatusLight from './AWSStatusLight';

const AWSModal = ({userid, AwsInstance, setAwsInstance}) => {
    const AWSInstanceName = `LMS_${userid}_LabInstance`;

    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getAWSInstanceId();
    }, [connected]);

    const getAWSInstanceId = async () => {
        const exists = await checkInstanceState( AWSInstanceName );

        if (!exists?.success) {
            setAwsInstance(prevState => ({
                ...prevState,
                status: false,
            }));
            setConnected(false);
        }

        if ( !AwsInstance.status && exists?.stateCode === 16 ) {
            setAwsInstance(prevState => ({
                ...prevState,
                id: exists.instanceId,
                name: AWSInstanceName,
                status: true,
                address: exists.address,
                eol: exists.eol
            }));
            setConnected(true);
        }
    }

    const createAWSInstance = async () => {
        setLoading(true);

        try {
            const create = await createInstanceState( AWSInstanceName );
      
            if ( create ) {
              setAwsInstance(prevState => ({
                ...prevState,
                id: create.AWSInstance,
                exists: true,
                eol: create.eol
              }));
            }
          } finally {
            getAWSInstanceId();
            setLoading( false );
          }
    }

    const handleCancel = () => {
        navigate( '/');
    }

    const faSpacing = {
        paddingRight: '1rem'
    }

    return (
        <Modal
            variant={ModalVariant.small}
            className='AWSServiceStart'
            title="Connecting to Amazon AWS Services"
            isOpen={true}
            showClose={false}
        >
            <Text>This Lab includes access to our Amazon AWS Service, by using this service you are agreeing to our <a href="https://www.mpirical.com/terms-and-conditions" target='blank' rel='noopener'>Terms and Conditions</a></Text>
            { AwsInstance.status && (
                    <Stack>
                        <StackItem>AWS Instance ID: {AwsInstance?.id} </StackItem>
                    </Stack>
                )}
                { !AwsInstance.status && (
                    <Stack>
                        { !AwsInstance.exists && (
                            <StackItem>
                                <Button onClick={() => createAWSInstance()} disabled={loading} className={ loading ? 'pf-v5-c-button-disabled' : ''}>
                                    { loading ? (
                                        <Text> <FontAwesomeIcon icon={faSpinner} spin="true" /> <span className={loading ? 'pf-v5-c-button-text-disabled' : ''}>Create AWS Instance</span></Text> 
                                    ) : (
                                        <Text> <FontAwesomeIcon icon={faServer} /> Create AWS Instance</Text>
                                    ) }
                                </Button>
                                {/* { !AwsInstance.exists && !loading && (
                                    <Button onClick={ () => handleCancel() } disabled={loading}>
                                        Back to Labs
                                    </Button>
                                )} */}
                                <AWSStatusLight AwsInstance={AwsInstance} loadingState={loading} ></AWSStatusLight>
                            </StackItem>
                        )}
                    </Stack>
                )}
        </Modal>
    )
}

export default AWSModal;