import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import '@patternfly/react-core/dist/styles/base.css';
import { jwtDecode } from 'jwt-decode';
import { RootProvider } from './components/RootContext';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import LabPage from './pages/Lab';
import LabListing from './components/labs/LabListing';
import LabsPage from './pages/Labs.js';
import MonitoringPage from './pages/Monitoring';
import './scss/Loader.js';

const withAuth = (WrappedComponent, userid) => {

    return (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            // Check for the specific cookie here
            const storedUserID = sessionStorage.getItem('globalUser');

            // If no user id, tell the user to go get one
            if (!storedUserID) {
                window.location.href = `${process.env.REACT_APP_COOKIERETURN}`;
            }
        }, [navigate]);

        // Render the wrapped component if the cookie is present
        return <WrappedComponent {...props} />;
    };
};



const App = () => {
    const storedUserID = sessionStorage.getItem('globalUser');
    const [isCssLoaded, setIsCssLoaded] = useState(false);

    useEffect(() => {
        const loadCss = async () => {
            try {
                // Dynamically import and apply environment-specific CSS files based on REACT_APP_ENV_CSS
                if (process.env.REACT_APP_ENV_CSS === 'tmo') {
                    await import('./css/NetTMo.css');
                } else if (process.env.REACT_APP_ENV_CSS === 'netx') {
                    await import('./css/NetXLabs.css');
                }

                // Set the loading state to true once CSS is loaded
                setIsCssLoaded(true);
            } catch (error) {
                console.error('Error loading CSS:', error);
            }
        };

        // Call the loadCss function
        loadCss();
    }, []);

    // Render nothing until CSS is loaded
    if (!isCssLoaded) {
        return null;
    }

    return (
        <RootProvider root={root} path="/">
            <Router>
                <Routes>
                    <Route index element={<AuthenticatedDashboard userid={storedUserID} />} />
                    <Route index path="login" element={<LoginPage userid={storedUserID} />} />
                    <Route exact path="dashboard" element={<AuthenticatedDashboard userid={storedUserID} />} />
                    <Route exact path="lab1" element={<AuthenticatedLabPage labid="1" userid={storedUserID} />} />
                    <Route exact path="lab2" element={<AuthenticatedLabPage labid="2" userid={storedUserID} />} />
                    <Route exact path="lab3" element={<AuthenticatedLabPage labid="3" userid={storedUserID} />} />
                    <Route exact path="lab4" element={<AuthenticatedLabPage labid="4" userid={storedUserID} />} />
                    <Route exact path="lab5" element={<AuthenticatedLabPage labid="5" userid={storedUserID} />} />
                    <Route exact path="lab6" element={<AuthenticatedLabPage labid="6" userid={storedUserID} />} />
                    <Route exact path="lab7" element={<AuthenticatedLabPage labid="7" userid={storedUserID} />} />
                    <Route exact path="lab8" element={<AuthenticatedLabPage labid="8" userid={storedUserID} />} />
                    <Route exact path="lab9" element={<AuthenticatedLabPage labid="9" userid={storedUserID} />} />
                    <Route exact path="lab10" element={<AuthenticatedLabPage labid="10" userid={storedUserID} />} />
                    <Route exact path="lab11" element={<AuthenticatedLabPage labid="11" userid={storedUserID} />} />
                    <Route exact path="netxlab1" element={<AuthenticatedLabPage labid="1" userid={storedUserID} />} />
                    <Route exact path="netxlab2" element={<AuthenticatedLabPage labid="2" userid={storedUserID} />} />
                    <Route exact path="netxlab3" element={<AuthenticatedLabPage labid="3" userid={storedUserID} />} />
                    <Route exact path="netxlab4" element={<AuthenticatedLabPage labid="4" userid={storedUserID} />} />
                    <Route exact path="netxlab5" element={<AuthenticatedLabPage labid="5" userid={storedUserID} />} />
                    <Route exact path="netxlab6" element={<AuthenticatedLabPage labid="6" userid={storedUserID} />} />
                    <Route exact path="netxlab7" element={<AuthenticatedLabPage labid="7" userid={storedUserID} />} />
                    <Route exact path="netxlab8" element={<AuthenticatedLabPage labid="8" userid={storedUserID} />} />
                    <Route exact path="netxlab9" element={<AuthenticatedLabPage labid="9" userid={storedUserID} />} />
                    <Route exact path="netxlab10" element={<AuthenticatedLabPage labid="10" userid={storedUserID} />} />
                    <Route exact path="netxlab11" element={<AuthenticatedLabPage labid="11" userid={storedUserID} />} />
                    <Route exact path="netxlab12" element={<AuthenticatedLabPage labid="12" userid={storedUserID} />} />
                    <Route exact path="lablisting" element={<LabListing labid="11" userid={storedUserID} />} />
                    <Route exact path="labs" element={<LabsPage userid={storedUserID} />} />
                    <Route exact path="monitoring" element={<MonitoringPage />} />
                </Routes>
            </Router>
        </RootProvider>
    );
};

const root = document.getElementById('root');
if (process.env.REACT_APP_ENV_CSS === 'tmo') {
    document.title = 'NetTMo Labs';
} else if (process.env.REACT_APP_ENV_CSS === 'netx') {
    document.title = 'NetXLabs';
}

const AuthenticatedDashboard = withAuth(Dashboard);
const AuthenticatedLabPage = withAuth(LabPage);

let globalUser = 0;
const queryParams = new URLSearchParams(document.location.search);

if (queryParams) {
    const providedJWT = queryParams.get('json');
    if (providedJWT) {
        const decoded = jwtDecode(providedJWT);
        if (decoded?.netxploreData?.userId) {
            globalUser = decoded.netxploreData;

            // Store in sessionStorage
            sessionStorage.setItem('globalUser', parseInt(globalUser.userId));
            sessionStorage.setItem('globalFirstName', globalUser.firstname);
            sessionStorage.setItem('globalLastName', globalUser.lastname);
            sessionStorage.setItem('globalProfilePicture', globalUser.picurl ? globalUser.picurl : 'none');

            window.history.replaceState({}, document.title, "/");
        } else {
            console.error('Invalid data in JWT:', decoded);
        }
    }
}

ReactDOM.createRoot(root).render(<App />);
