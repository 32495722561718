import { Button, Modal, ModalVariant, StackItem } from '@patternfly/react-core';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardPopup = ( ) => {
    const [showPopup, setShowPopup] = useState(true);
    const navigate = useNavigate();

    const handleModalToggle = () => {
        setShowPopup(false);

        // Need to change the state to make sure we dont display on page refresh
        const newLocation = { ...navigate.location, replace: true, state: { hadTimedOut: false} };
        navigate(newLocation);
    };
    
    return (
        <Modal
            variant={ModalVariant.small}
            title="Session Timed Out"
            isOpen={showPopup}
            onClose={handleModalToggle}
        >
            The AWS Instance no longer exists.
        </Modal>
    );
}

export default DashboardPopup;
