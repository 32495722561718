import React, { useState, useEffect } from 'react';
import {
    Page,
    Brand,
    Masthead,
    MastheadBrand,
    MastheadContent,
    MastheadMain,
    MastheadToggle,
    PageToggleButton,
    PageSidebar,
    PageSidebarBody,
    Nav,
    NavItem,
    NavList,
    Text,
    Divider
} from "@patternfly/react-core";

import { useNavigate } from 'react-router-dom';
import logo from '../assets/mpirical-logo.png';
import { NavLink } from "react-router-dom";
import labsLogo from '../assets/netxlabs-logo.png';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import gradient from '../assets/gradient.png'

import {
    UserIcon,
    TaskIcon,
    MonitoringIcon
} from "@patternfly/react-icons"; // Import your icons

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFlask,
    faUser,
    faGears,
    faGear,
    faMap,
    faFlaskGear,
    faGearCode
} from '@fortawesome/pro-solid-svg-icons';

const NetXLayout = ({ content }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isScreenWidthSmall, setIsScreenWidthSmall] = useState(false);
    const [isSideMenuHovered, setIsSideMenuHovered] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            // Update the state based on the current screen width
            setIsSidebarOpen(window.innerWidth > 1200); // Adjust the threshold as needed
            setIsScreenWidthSmall(window.innerWidth < 1200); // Adjust the threshold as needed
        };

        // Initial check on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const header = (
        <Masthead style={{ backgroundImage: `url(${gradient})` }} className='mp-masthead'>
            <div style={{ backgroundImage: `url(${labsLogo})` }} className="mp-headingLogo"></div>
            {isScreenWidthSmall && (
                <MastheadToggle>
                    <PageToggleButton
                        variant="plain"
                        aria-label="Global navigation"
                        isSidebarOpen={isSidebarOpen}
                        onSidebarToggle={onSidebarToggle}
                        id="vertical-nav-toggle"
                    >
                        <BarsIcon />
                    </PageToggleButton>
                </MastheadToggle>
            )}
            <MastheadMain>
                <MastheadBrand>
                    <Brand
                        alt="NetTMo Labs"
                        src={logo}
                        heights={{ default: '49px', sm: '27px', md: '49px' }}
                    />
                </MastheadBrand>
            </MastheadMain>
        </Masthead>
    );

    const sidebar = (
        <PageSidebar
            isSidebarOpen={isSidebarOpen}
            id="vertical-sidebar"
            className={'mp-sideBar' && isSideMenuHovered ? 'mp-sideBar mp-sideBar--Hover' : 'mp-sideBar'}
            onMouseEnter={() => setIsSideMenuHovered(true)}
            onMouseLeave={() => setIsSideMenuHovered(false)}
        >
            <PageSidebarBody>
                <Nav>
                    <NavList>
                        <NavItem itemId={0} className='mp-sideBarLink mp-sideBarLink--First'>
                            <NavLink to="/">
                                <FontAwesomeIcon
                                    className='mp-sideBarLinkIcon'
                                    icon={faUser}
                                />
                                <Text className={isSideMenuHovered ? 'mp-sideBarLinkText mp-sideBarLinkText--Hover' : 'mp-sideBarLinkText'}>Dashboard</Text>
                            </NavLink>
                        </NavItem>
                        <Divider className='mp-sideBarDivider' />
                        <NavItem itemId={1} className='mp-sideBarLink'>
                            <NavLink to="/Labs">
                                <FontAwesomeIcon
                                    className='mp-sideBarLinkIcon'
                                    icon={faFlask}
                                />
                                <Text className={isSideMenuHovered ? 'mp-sideBarLinkText mp-sideBarLinkText--Hover' : 'mp-sideBarLinkText'}>Labs</Text>
                            </NavLink>
                        </NavItem>

                        <NavItem itemId={2} className='mp-sideBarLink'>
                            <NavLink to="/monitoring">
                                <FontAwesomeIcon
                                    className='mp-sideBarLinkIcon'
                                    icon={faGearCode}
                                />
                                <Text className={isSideMenuHovered ? 'mp-sideBarLinkText mp-sideBarLinkText--Hover' : 'mp-sideBarLinkText'}>Configuration</Text>
                            </NavLink>
                        </NavItem>

                        <NavItem itemId={3} className='mp-sideBarLink'>
                            <NavLink to={`${process.env.REACT_APP_URL}/NetXLabs_Reference_Diagram.pdf`} target='_blank'>
                                <FontAwesomeIcon
                                    className='mp-sideBarLinkIcon'
                                    icon={faMap}
                                />
                                <Text className={isSideMenuHovered ? 'mp-sideBarLinkText mp-sideBarLinkText--Hover' : 'mp-sideBarLinkText'}>Reference Map</Text>
                            </NavLink>
                        </NavItem>

                    </NavList>
                </Nav>
            </PageSidebarBody>
        </PageSidebar>
    );

    return (
        <Page
            className={isSideMenuHovered || isScreenWidthSmall ? 'mp-sideBarOpen' : 'mp-sideBarClosed'}
            header={header}
            sidebar={sidebar}
        >
            {content}
        </Page>
    );
};

export default NetXLayout;