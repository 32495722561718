import React, { useEffect, useState } from 'react';
import { QuestionCircleIcon} from "@patternfly/react-icons"; // Import your icons
import {Modal, ModalVariant, Button} from '@patternfly/react-core';
import { CodeEditor, Language } from "@patternfly/react-code-editor"; // Import your icons

const MonitoringModal = ( {name, path, id} ) => {
  const [isNodeConfigModalOpen, setIsNodeConfigModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState("Loading...");
  const modalPath = path;
  const modalId = id;
  const modalName = name;

  const handleNodeConfigModalToggle = () => {
    setIsNodeConfigModalOpen(!isNodeConfigModalOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await fetch( path );
          const data = await response.text();
  
          setModalData(data);
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      };

      fetchData();
  }, []);


  const handleResponse = ( res, id ) => {
    const element = document.createElement("a");
    const dataToDownload = res;
    const file = new Blob([dataToDownload], {
      type: "application/yaml",
    });
    element.href = URL.createObjectURL(file);
    element.download = id + `.yaml`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  
  return (
    <React.Fragment>
        <Button
            onClick={handleNodeConfigModalToggle}
            variant="plain"
        >
            View Config
        </Button>
        <Modal
            id={`node_modal_${id}`}
            variant={ModalVariant.small}
            isOpen={isNodeConfigModalOpen}
            onClose={handleNodeConfigModalToggle}
            title={`${modalName} Configuration`}
            className='mp-monitoringModal mp-rounded'
        >
            <CodeEditor
                code={modalData}
                height="400px"
                language={Language.yaml}
                isLanguageLabelVisible
                isReadOnly={true}
            />
            <Button
                onClick={() => { handleResponse(modalData, modalId) }}
                variant="plain"
            >
                Download
            </Button>
        </Modal>
    </React.Fragment>
  );
};

export default MonitoringModal;