import React, { useEffect, useState } from "react";
import { Flex, FlexItem, Grid, GridItem, PageSection, Panel, PanelMain, PanelMainBody, Title } from "@patternfly/react-core";

import MonitoringCard from "./MonitoringCard";

const MonitoringListing = () => {
    const [Monitoring, setMonitoringData] = useState([]);
    
    useEffect( () => {
        // Fetch data from the local JSON file
        fetch( 'data/NetXMonitoring.json' ) // Replace with the correct path
        .then( ( response ) => response.json() )
        .then( ( data ) => setMonitoringData( data ) )
        .catch( ( error ) => console.error( 'Error fetching data: ', error ) );
    }, [] ); // The empty dependency array ensures this effect runs only once

    const panelStyle = {
        padding: '0', // Set padding to '0' to remove padding
    };

    return (
        <PageSection>
            <Title headingLevel="h1"  style={{textAlign: 'center', padding: '25px 0 50px 0', fontSize: '30px'}}>Configuration</Title>
            <Panel className="Monitoring">
                <PanelMain style={panelStyle}>
                    <PanelMainBody>
                        <Flex
                            justifyContent={{ default: 'justifyContentCenter' }}
                            gap={{default: 'gapLg'}}
                            alignSelf={{ default: 'alignSelfFlexStart' }}
                        >
                            {Monitoring.map((node, index) => (
                                <FlexItem key={node.id} className="Monitoring__card">
                                    <MonitoringCard
                                        id={node.id}
                                        name={node.name}
                                        icon={node.icon}
                                        target={node.target}
                                        cmd={node.cmd}
                                    />
                                </FlexItem>
                            ))}
                        </Flex>
                    </PanelMainBody>
                </PanelMain>
            </Panel>
        </PageSection>
    );
}

export default MonitoringListing;