import { useState, useEffect } from "react";
import { io } from "socket.io-client";

/**
 * Custom React hook to fetch a directory listing from the server.
 *
 * Used to retrieve a list of available log files in a specified directory.
 *
 * @param {string} path - The path to the directory.
 * @param {string} fileType - The type of files to list from the directory.
 * @returns {{
 *   directoryListing: Object[],
 *   error: string,
 *   isLoading: boolean
 * }} A list of directory contents, potential error message, and loading state.
 */
const useFetchDirectory = (path, fileType, AwsInstance) => {
    const [directoryListing, setDirectoryListing] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Establish a socket connection to the server.
        const socket = io(process.env.REACT_APP_API_ENDPOINT, { query: { AWSHost: AwsInstance.address } });

        // Function to request a directory listing based on the provided path and file type.
        const fetchListing = () => {
            socket.emit("getDirectoryListing", path, fileType);
            setIsLoading(true);
        };

        // Set up event listeners:

        // When connected to the server, request the directory listing.
        socket.on("connect", fetchListing);

        // Handle connection errors.
        socket.on("connect_error", (err) => {
            setError(`Connection error: ${err.message}`);
            setIsLoading(false);
        });

        // Handle connection timeouts.
        socket.on("connect_timeout", () => {
            setError("Connection to server timed out.");
            setIsLoading(false);
        });

        // When receiving directory data from the server:
        socket.on("directoryListing", (data) => {
            setDirectoryListing(data);
            setIsLoading(false);
        });

        // Handle other generic socket errors.
        socket.on("error", (err) => {
            // setError(err);
            // setIsLoading(false);
        });

        // Cleanup: Remove event listeners to avoid potential memory leaks.
        return () => {
            socket.off("connect", fetchListing);
            socket.off("connect_error");
            socket.off("connect_timeout");
            socket.off("directoryListing");
            socket.off("error");
            // Note: The socket is not disconnected since it may be reused in other parts of the application.
        };
    }, [path, fileType]);

    return { directoryListing, error, isLoading };
};

export default useFetchDirectory;
