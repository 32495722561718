import React, { createContext, useContext } from 'react';

const RootContext = createContext( null );

export const RootProvider = ({ root, children }) => (
  <RootContext.Provider
    value={root}
  >
    {children}
  </RootContext.Provider>
);

export const useRoot = () => useContext(RootContext);