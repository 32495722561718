import React from 'react';
import {
    Button,
    ButtonVariant,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Progress,
    Title,
    TitleSizes,
    Brand,
    Split,
    SplitItem,
    Level,
    LevelItem
} from '@patternfly/react-core';
import MonitoringModal from './MonitoringModal';
import { ArrowRightIcon, EyeIcon, RedoIcon } from '@patternfly/react-icons';

const MonitoringCard = ({ id, name, icon, target, command }) => (
    <Card
        className='mp-rounded mp-lessonTile'
        isClickable
        isCompact
    >
        <CardHeader
            className='mp-lessonTileHeader'
            style={{
                    height: '180px',
                    backgroundPosition: '20px 50%',
                    backgroundSize: '100px',
                    backgroundImage: `url(${icon})`,
                    backgroundRepeat: 'no-repeat'
            }}
        >
        <CardTitle>
            <Title headingLevel="h2" style={{paddingLeft: '140px', paddingTop: '70px'}}>{name}</Title>
        </CardTitle>
        </CardHeader>
        <CardBody className='mp-lessonTileBody mp-monitoringBody' isFilled>
            <Level className='mp-monitonringLink'>
                <LevelItem><EyeIcon/></LevelItem>
                <LevelItem>
                    <MonitoringModal
                        path = {target}
                        id = {id}
                        name = {name}
                    />  
                </LevelItem>
                <LevelItem><ArrowRightIcon/></LevelItem>
            </Level>
        </CardBody>
    </Card>
);

export default MonitoringCard;
