import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    TabTitleText,
    TabAction,
    Popover,
    Button,
    Panel,
    Bullseye,
    Split,
    SplitItem
} from '@patternfly/react-core';
import LabTerminal from '../classes/terminal.class';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import '../../../scss/mp-wizard-console.scss';
import { v4 as uuidv4 } from 'uuid';

export const LessonConsole = ({ terminalid, AwsInstance }) => {
    console.log("render");
    const terminals = [];
    const [activeTabKey, setActiveTabKey] = React.useState(0);
    const [tabs, setTabs] = React.useState(['Terminal 1']);
    const [newTabNumber, setNewTabNumber] = React.useState(2);
    const [activeFooterState, setActiveFooterState] = React.useState(0);
    const [footerExpand, setFooterExpand] = useState(false);
    const tabComponentRef = React.useRef();
    const firstMount = React.useRef(true);

    const onClose = (tabIndex) => {
        const tabIndexNum = tabIndex;
        let nextTabIndex = activeTabKey;
        if (tabIndexNum < activeTabKey) {
            nextTabIndex = activeTabKey - 1 > 0 ? activeTabKey - 1 : 0;
        } else if (activeTabKey === tabs.length - 1) {
            nextTabIndex = tabs.length - 2 > 0 ? tabs.length - 2 : 0;
        }
        setTabs(tabs.filter((tab, index) => index !== tabIndex));
        setActiveTabKey(nextTabIndex);
    };

    const onAdd = () => {
        setTabs([...tabs, `Terminal ${newTabNumber}`]);
        setActiveTabKey(tabs.length);
        setNewTabNumber(newTabNumber + 1);
    };

    const openTestTerminal = (id) => {
        console.log(`${terminalid}-terminal-modal-${id}`);
        const terminalInstance = new LabTerminal({
            container: `${terminalid}-terminal-button-${id}`,
            initialCommandTrigger: null,
            initialCommand: null,
            AWSHost: AwsInstance,
        });
        terminalInstance.create(); // This should trigger componentDidMount
        console.log("terminals", terminals);
        expandFooterTerminal();
    }
    const toggleLessonFooter = () => {
        if (activeFooterState) {
            setActiveFooterState(false);
        } else {
            setActiveFooterState(true);
        }
    }

    const toggleFooterTerminal = () => {
        setFooterExpand(!footerExpand);
    }

    const expandFooterTerminal = () => {
        setFooterExpand(true);
    }


    React.useEffect(() => {
        if (firstMount.current) {
            firstMount.current = false;
            return;
        } else {
            const first = tabComponentRef.current.tabList.current.childNodes[activeTabKey + 1];
            first && first.firstChild.focus();
        }
    }, [tabs]);

    return (
        <Split>
            <SplitItem isFilled>
                <div style={{ width: '100%', height: '100%' }} className='mp-wizardConsole'>
                    <Tabs
                        activeKey={activeTabKey}
                        onSelect={(event, tabIndex) => setActiveTabKey(tabIndex)}
                        onClose={(event, tabIndex) => onClose(tabIndex)}
                        onAdd={onAdd}
                        aria-label="Tabs in the addable/closeable example"
                        addButtonAriaLabel="Add new tab"
                        role="region"
                        ref={tabComponentRef}
                        className='mp-wizardConsole__Tabs'
                    >
                        {tabs.map((tab, index) => {
                            console.log("tabs.length", tabs.length, "index", index, "activeTabKey", activeTabKey);
                            const guid = tab.replace(/\s+/g, '').toLowerCase();  // Use the corresponding guid
                            return (
                                <Tab
                                    key={guid}
                                    eventKey={index}
                                    aria-label={`Dynamic ${tab}`}
                                    title={<TabTitleText>{tab}</TabTitleText>}
                                    closeButtonAriaLabel={`Close ${tab}`}
                                    isCloseDisabled={tabs.length === 1 || index !== tabs.length - 1}
                                    className='mp-wizardConsole__Tab'
                                >
                                    <Panel id={`${terminalid}-terminal-modal-${guid}`} className={`mp-terminalHolder ${footerExpand ? 'mp-terminalHolder--Expanded' : ''}`}>
                                        <Bullseye id={`${terminalid}-terminal-button-${guid}`} style={{ width: '100%' }}>
                                            <Button onClick={() => openTestTerminal(guid)}>Connect to Lab</Button>
                                        </Bullseye>
                                    </Panel>
                                </Tab>
                            )
                        })}
                    </Tabs>
                </div>
            </SplitItem>
            <SplitItem className='mp-wizardConsole__Button__Container'>
                <Button
                    className={`mp-wizardConsole__Button ${footerExpand ? 'mp-wizardConsole__Button--Expanded' : ''}`}
                    onClick={toggleFooterTerminal}
                >
                    <FontAwesomeIcon icon={faAngleUp} />
                </Button>
            </SplitItem>
        </Split>
    );
};

export default LessonConsole;