import React, { useState, useEffect } from 'react';
import terminateInstance from '../../API/AWS/TerminateInstance';
import { useNavigate } from 'react-router-dom';
import { Panel, Popover } from '@patternfly/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';

const CountdownTimer = ({ AwsInstance }) => {
    const navigate = useNavigate();

    let timeDiff = 0;

    const calculateTimeRemaining = () => {
        if (AwsInstance.status) {
            timeDiff = new Date(AwsInstance.eol) - new Date();
            if (timeDiff <= 0) {
                terminateInstance(AwsInstance?.id);

                navigate('/', {
                    state: {
                        'hadTimedOut': true
                    }
                });

                return {
                    hours: '00',
                    minutes: '00',
                    seconds: '00'
                };
            }

            let totalSeconds = Math.floor(timeDiff / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;

            return {
                hours: String(hours).padStart(2, '0'),
                minutes: String(minutes).padStart(2, '0'),
                seconds: String(seconds).padStart(2, '0')
            };
        }

        return {
            hours: String(0).padStart(2, '0'),
            minutes: String(0).padStart(2, '0'),
            seconds: String(0).padStart(2, '0')
        };
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const { hours, minutes, seconds } = timeRemaining;
    const fiveMinutes = 300000;

    return (
        <Panel style={{overflow: 'hidden'}} className={`mp-wizardStatus ${timeDiff > 3600 ? (timeDiff > fiveMinutes) ? 'mp-wizardStatus--green' : 'mp-wizardStatus--orange' : ''}`}>
            Time left on instance: <span style={{width: '100px', display: 'inline-block'}}>{hours}:{minutes}:{seconds}</span>
            <Popover
                triggerAction="hover"
                aria-label="AWS Information"
                headerContent={<div>Time on instance</div>}
                bodyContent={<div>This Lab uses Amazon AWS, you are given a limited time on this instance and you will be given the option to extend this time later if you need it.</div>}
            >
                <FontAwesomeIcon icon={faCircleQuestion} />
            </Popover>
        </Panel>
    );
};

export default CountdownTimer;
