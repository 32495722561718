import { useEffect, useState } from "react"
import '../../../scss/AwsStatusLight.scss'

const AWSStatusLight = ( { AwsInstance, loadingState } ) => {
    const [currentStatus, setCurrentStatus ] = useState( 'off' );

    useEffect(() => {
        if ( loadingState ) {
            setCurrentStatus( 'loading' );
        } else if (!loadingState && AwsInstance.status ) {
            setCurrentStatus( 'on' );
        } else {
            setCurrentStatus( 'off' );
        }

    }, [ AwsInstance, loadingState ])

    return (
        <div className="AWSStatusLight">
            <span className="AWSStatusLight__text">Status: </span>
            <span className={`AWSStatusLight__bulb AWSStatusLight__bulb--red ${currentStatus == 'off' ? 'AWSStatusLight__bulb--active' : ''}`}></span>
            <span className={`AWSStatusLight__bulb AWSStatusLight__bulb--orange ${currentStatus == 'loading' ? 'AWSStatusLight__bulb--active' : ''}`}></span>
            <span className={`AWSStatusLight__bulb AWSStatusLight__bulb--green ${currentStatus == 'on' ? 'AWSStatusLight__bulb--active' : ''}`}></span>
        </div>
    )
}

export default AWSStatusLight