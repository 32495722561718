import React, { useEffect, useState } from "react";
import { Flex, FlexItem, Grid, GridItem, PageSection, Panel, PanelMain, PanelMainBody, Title } from "@patternfly/react-core";
import RootLayout from "../layouts/RootLayout";

import MonitoringListing from "../components/monitoring/MonitoringListing";

const MonitoringPage = () => {
    return <RootLayout content={<MonitoringListing />} />
}

export default MonitoringPage;