import React, { useEffect, useState } from "react";
import { Page, PageSection } from "@patternfly/react-core";
import { useNavigate } from 'react-router-dom';
import RootLayout from "../layouts/RootLayout";
import LessonWizard from "../components/labs/LabWizard";
import fetchAPIData from "../components/API/LMS/GetData";
import pushAPIData from "../components/API/LMS/SetData";

const LabPage = ({ labid, userid }) => {
    const [LabsData, setLabsData] = useState();
    const [SectionData, setSectionData] = useState();
    const [UserData, setUserData] = useState();

    const setSectionState = (labID, sectionID, sectionValue) => {
      setUserData((prevUserData) => {
        const updatedUserData = {
          ...prevUserData,
          data: prevUserData.data.map((labDataItem) => {
            if (labDataItem.labid === labID) {
              // Check if the section with sectionID exists
              const sectionIndex = labDataItem.sections.findIndex(
                (section) => section.id === sectionID
              );
    
              // If section with sectionID doesn't exist, add a new section
              if (sectionIndex === -1) {
                return {
                  ...labDataItem,
                  sections: [
                    ...labDataItem.sections,
                    { id: sectionID, value: sectionValue },
                  ],
                };
              }
    
              // Update the existing section with a new value
              return {
                ...labDataItem,
                sections: labDataItem.sections.map((section, index) => {
                  if (index === sectionIndex) {
                    return {
                      ...section,
                      value: sectionValue === "true", // Convert string to boolean
                      // You can update other properties as needed
                    };
                  }
                  return section;
                }),
              };
            }
            return labDataItem;
          }),
        };
    
        return updatedUserData;
      });
    };

    useEffect(() => {
      const fetchLabsData = async () => {
        try {
          if (labid) {
            const response = await fetch( process.env.REACT_APP_DATA_FILE );
            const data = await response.json();
            const foundLesson = data.find((item) => item.lessonID === labid);
            setLabsData(foundLesson);
          }
        } catch (error) {
          console.error('Error fetching labs data:', error);
        }
      };
  
      fetchLabsData();
    }, [labid]);
  
    useEffect(() => {
      const fetchSectionData = async () => {
        try {
          if (LabsData && LabsData.lessonDataFile) {
            const response = await fetch(process.env.REACT_APP_LABS_FOLDER + '/' + LabsData.lessonDataFile + '.json');
            const data = await response.json();
            setSectionData(data);
          }
        } catch (error) {
          console.error('Error fetching section data:', error);
        }
      };
  
      fetchSectionData();
    }, [LabsData]);

    useEffect(() => {
      const fetchAPIDataAsync = async () => {
        try {
          const response = await fetchAPIData({ userid: userid });
          setUserData(response);
        } catch (error) {
          // Handle errors if needed
        }
      };
      
      fetchAPIDataAsync();
    },[SectionData]);

    useEffect(() => {
      const pushAPIDataAsync = async () => {
        try {
          await pushAPIData({ userid: userid, UserData: UserData });
        } catch (error) {
          // Handle errors if needed
        }
      };
      
      pushAPIDataAsync();
    },[UserData]);
    
    // Use useEffect to perform the state update after the initial render
  useEffect(() => {
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

    return (
      LabsData && SectionData && UserData && (
        <RootLayout
          content={
            <LessonWizard
              labID={labid}
              LabsData={LabsData}
              SectionData={SectionData}
              UserData={UserData}
              setUserData={setUserData}
              userid={userid}
            />
          }
        />
      )
    );
  };
  

export default LabPage;
