import React, { useEffect, useState } from "react";
import RootLayout from "../layouts/RootLayout";
import LabListing from "../components/labs/LabListing";
import DashboardLayout from "../components/dashboard/DashboardLayout";


const LabsPage = ({ userid }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    // Set the page title when the component mounts
    document.title = 'NetXLabs';
  }, []); // The empty dependency array ensures this effect runs only once

  const onSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
   return <RootLayout content={<LabListing userid = {userid} />} />
};

export default LabsPage;
