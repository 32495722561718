import React, { useEffect, useState } from "react";
import {
    Bullseye,
    Grid,
    GridItem,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Split,
    SplitItem,
    Stack,
    StackItem,
    Title,
    TitleSizes
} from "@patternfly/react-core";
import { NavLink } from "react-router-dom";
import { ChartDonutUtilization } from '@patternfly/react-charts';
import DashboardHeader from "./DashboardHeader";
import DashboardLabTiles from "./DashboardLabTiles";
import LabListing from "../labs/LabListing";
import GlobalLessonData from "../API/LMS/LabData";
import GlobalUserData from "../API/LMS/UserData";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFlask,
    faUser,
    faGears,
    faGear,
    faMap,
    faFlaskGear,
    faGearCode
} from '@fortawesome/pro-solid-svg-icons';

const DashboardLayout = ({ userid }) => {
    const Lessons = GlobalLessonData();
    const UserData = GlobalUserData({ userid });
    const [CompletionCount, setCompletionCount] = useState(0);

    const getUserLabProgress = (labID) => {

        let lessonMax = 0;
        let lessonUser = 0;

        if (!Lessons || !UserData) {
            return null;
        }

        for (const lesson of Lessons) {
            if (lesson.lessonID === labID) {
                lessonMax = lesson.completionSteps;
                
            }
        }

        for (const lab of UserData.data) {
            if (lab.labid === labID) {
                lessonUser = lab.sections.length;
            }
        }

        //console.log("LabID", labID, "lessonMax:", lessonMax, "UserSteps", lessonUser);
        if (lessonMax > 0 && lessonUser > 0) {
            return (lessonUser / lessonMax) * 100;
        } else {
            return 0;
        }

    }

    useEffect(() => {
        if (Lessons && UserData) {
            let count = 0;

            Lessons.forEach((lesson) => {
                const progress = getUserLabProgress(lesson.lessonID);
                if (progress > 99 ) {
                    count++;
                }
            });

            setCompletionCount(count);
        }
    }, [UserData, Lessons]);

    return (
        <Panel className="mp-dashboardWrapper">
            <Panel className="mp-dashboardWrapperHeader">
                <DashboardHeader />
            </Panel>
            <Panel className="mp-dashboardWrapperContent">
                <Grid className="mp-dashboard" rows={8}>
                    <GridItem
                        className="mp-dashboard__Left"
                        sm={12}
                        md={12}
                        lg={12}
                        xl={8}
                        xl2={8}
                        span={12}
                        rowSpan={1}
                    >
                        <Stack className="mp-dashboard__Exercise">
                            <StackItem>
                                <Title className="mp-dashboard__PanelHeader" headingLevel="h2">Latest Lab Exercises</Title>
                            </StackItem>
                            <StackItem isFilled>
                                <Panel className="mp-dashboard__ExerciseWrapper"
                                    style={{
                                        position: 'relative',
                                        overflow: 'hidden',

                                    }}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            overflowY: 'auto',
                                            padding: '20px'
                                        }}
                                        className='mp-labTileHeaderImage'

                                    >
                                        <DashboardLabTiles userid={userid} labLimit={4} />
                                    </div>
                                </Panel>
                            </StackItem>
                        </Stack>
                    </GridItem>
                    <GridItem className="mp-dashboard__Right"
                        sm={12}
                        md={12}
                        lg={12}
                        xl={4}
                        xl2={4}
                        span={12}
                        rowSpan={1}
                    >
                        <Stack className="mp-dashboard__Column" hasGutter rows={12}>
                            <StackItem>
                                <NavLink to="/Labs" key={1} style={{ textDecoration: 'none' }}>
                                    <Panel
                                        className={'mp-dashboard__Panel mp-dashboard__Link'}
                                    >
                                        <Bullseye>
                                            <Title headingLevel="h2"><FontAwesomeIcon
                                                className='mp-sideBarLinkIcon'
                                                icon={faFlask}
                                            />Lab Exercises</Title>
                                        </Bullseye>
                                    </Panel>
                                </NavLink>
                            </StackItem>
                            <StackItem>
                                <NavLink to="/monitoring" key={2} style={{ textDecoration: 'none' }}>
                                    <Panel className={'mp-dashboard__Panel mp-dashboard__Link'}>
                                        <Bullseye>
                                            <Title headingLevel="h2"><FontAwesomeIcon
                                                className='mp-sideBarLinkIcon'
                                                icon={faGearCode}
                                            />Configuration</Title>
                                        </Bullseye>
                                    </Panel>
                                </NavLink>
                            </StackItem>
                            <StackItem>
                                <NavLink to={`${process.env.REACT_APP_URL}/NetXLabs_Reference_Diagram.pdf`} target='_blank' key={3} style={{ textDecoration: 'none' }}>
                                    <Panel className={'mp-dashboard__Panel mp-dashboard__Link'}>
                                        <Bullseye>
                                            <Title headingLevel="h2"><FontAwesomeIcon
                                                className='mp-sideBarLinkIcon'
                                                icon={faMap}
                                            />Reference Map</Title>
                                        </Bullseye>
                                    </Panel>
                                </NavLink>
                            </StackItem>
                            <StackItem isFilled className="mp-minheight" id="mp-minheight-1" data-targetHeight={320}>
                                <Panel className="mp-dashboard__Panel mp-dashboard__Chart">
                                    <Stack>
                                        <StackItem>
                                            <Title className="mp-dashboard__PanelHeader" headingLevel="h2">Lab Progress</Title>
                                        </StackItem>
                                        <StackItem isFilled>
                                            <Bullseye className="mp-dashboard__ChartContent">
                                                <ChartDonutUtilization
                                                    ariaDesc="Storage capacity"
                                                    ariaTitle="Donut utilization chart example"
                                                    constrainToVisibleArea
                                                    data={{ x: 'Lab Exercises Complete', y: Math.round((CompletionCount / (Lessons?.length || 1)) * 100) }}
                                                    labels={({ datum }) => datum.x ? `${datum.x}: ${datum.y}%` : null}
                                                    legendData={[{ name: `Lab Exercises Complete` }, { name: 'Lab Exercises Left' }]}
                                                    legendOrientation="vertical"
                                                    legendPosition="bottom"
                                                    name="Exercise Progress"
                                                    innerRadius={60}
                                                    padding={{
                                                        bottom: 75, // Adjusted to accommodate legend
                                                        left: 20,
                                                        right: 20,
                                                        top: 0
                                                    }}
                                                    subTitle={`of ${Lessons?.length ?? 0} Labs`}
                                                    title={`${Math.round((CompletionCount / (Lessons?.length || 1)) * 100)}%`}
                                                    thresholds={[{ value: 60 }, { value: 90 }]}
                                                />
                                            </Bullseye>
                                        </StackItem>
                                    </Stack>
                                </Panel>
                            </StackItem>
                        </Stack>
                    </GridItem>
                </Grid>
            </Panel>
        </Panel>
    )
}

export default DashboardLayout;