import React, { useEffect, useState } from "react";
import {
    Panel,
    PanelMain,
    PanelMainBody,
    Grid,
    GridItem,
    Card,
    CardHeader,
    CardBody,
    Title,
    Text,
    Progress,
    Badge
} from "@patternfly/react-core";
import { NavLink } from "react-router-dom";

const LabTile = ({ UserData, Lessons, lesson }) => {
    const getUserLabProgress = (labID) => {
        let lessonMax = 0;
        let lessonUser = 0;

        if (!Lessons || !UserData) {
            return null;
        }

        for (const lesson of Lessons) {
            if (lesson.lessonID === labID) {
                lessonMax = lesson.completionSteps;
            }
        }

        for (const lab of UserData.data) {
            if (lab.labid === labID) {
                lessonUser = lab.sections.length;
            }
        }

        if (lessonMax > 0 && lessonUser > 0) {
            return (lessonUser / lessonMax) * 100;
        } else {
            return 0;
        }

    }

    const setLabBadges = (lab) => {
        if (Array.isArray(lab.lessonMetadata.lessonCategory)) {
            return lab.lessonMetadata.lessonCategory.map((badge, index) => (
                <Badge key={index}>{badge}</Badge>
            ));
        } else {
            // Handle the case where lessonCategory is not an array
            return <Badge>{lab.lessonMetadata.lessonCategory}</Badge>;
        }
    }


    return (
        <Card
            className='mp-rounded mp-labTile'
            isRounded
        >
            <NavLink to={`/${lesson.lessonLink}`} key={2} style={{ textDecoration: 'none' }}>
                <CardHeader
                    selectableActions={{
                        to: lesson.lessonLink,
                        selectableActionId: lesson.lessonID,
                        selectableActionAriaLabelledby: 'Lab Lesson',
                        name: 'lab-lesson'
                    }}
                    className='mp-labTileHeader'
                    style={{
                        position: 'relative',
                        overflow: 'hidden',

                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${lesson.lessonPreviewImage})`,
                            backgroundRepeat: 'no-repeat',
                            zIndex: -1
                        }}
                        className='mp-labTileHeaderImage'

                    />
                    {setLabBadges(lesson)}
                </CardHeader>
                <CardBody className='mp-labTileBody'>

                    <Title className="mp-tileTitle" headingLevel="h2">{lesson.lessonName}</Title>
                    <Text className="mp-tileDescription">{lesson.lessonDescription}</Text>
                    <Progress value={getUserLabProgress(lesson.lessonID)} min={0} max={100} />
                </CardBody>
            </NavLink>
        </Card>
    )
}

export default LabTile;