import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Wizard,
    WizardNav,
    WizardNavItem,
    WizardStep,
    useWizardContext,
    Panel,
    Button,
    Title,
    Divider,
    Text,
    Icon,
    Progress,
    ProgressSize,
    ProgressMeasureLocation
} from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import { useRoot } from "../RootContext";
import LabWizardStep from './LabWizardStep';
import AngleLeftIcon from '@patternfly/react-icons/dist/esm/icons/angle-left-icon';
import checkInstanceState from '../API/AWS/checkState';
import LessonConsole from './items/console';
import createInstanceState from '../API/AWS/CreateInstanceState';
import AWSModal from './items/AWSModal';
import AWSExtendModal from './items/AWSExtendModal';
import CountdownTimer from './items/AWSSessionTimer';
import terminateInstance from '../API/AWS/TerminateInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

const LessonWizard = ({ labID, LabsData, SectionData, UserData, setUserData, userid }) => {
    const root = useRoot();
    const [AwsInstance, setAwsInstance] = useState({
        id: null,
        name: null,
        status: false,
        exists: false,
        eol: null,
        address: null
    });
    const [AwsInstanceNeeded, setAwsInstanceNeeded] = useState(false);
    const [AwsInstanceReady, setAwsInstanceReady] = useState(false);
    const [LabProgress, setLabProgress] = useState(0);

    const onStepChange = (event, currentStep, prevStep) => {
        //setSectionState(prevStep.id);
        markSectionComplete(prevStep.id);
    };
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // Programatically navigate to the specified URL
        navigate('/');
    };

    const AwsInstanceRef = useRef();

    // Empty dependency array so this only triggers the unmount process once fully unmounted
    // and not during some re-renders
    useEffect(() => {
        
        // Full unmount of lab wizard
        return () => {
            if ( AwsInstanceRef.current.id ) {
                terminateInstance( AwsInstanceRef.current.id );
            }
        }
    }, [])

    useEffect(() => {
        if (LabsData.includesAWS) {
            // Additional logic you want to perform when AwsInstance is ready
            setAwsInstanceNeeded(true);
        }
    }, [LabsData.includesAWS, AwsInstance]);

    // Effect for rendering the title
    useEffect(() => {
        if (LabsData.includesAWS && AwsInstance.status) {
            const wizardFooter = root.querySelector('.pf-v5-c-wizard__footer');

            const footerContainer = document.createElement('div');
            footerContainer.className = 'mp-footerConsole';
            //footer
            if (!root.querySelector('.mp-footerConsole')) {
                ReactDOM.createRoot(footerContainer).render(wizardLabFooter(LabsData.lessonID));
                wizardFooter.parentNode.appendChild(footerContainer, wizardFooter.firstChild);
            }
        }

        AwsInstanceRef.current = AwsInstance;
    }, [root, LabsData, AwsInstance]);

    const findStatusByLabAndSectionId = (data, labId, sectionId) => {
        // Find the lab item with the matching labId
        // Iterate through each labid object
        for (const lab of data.data) {
            // Check if labid is 1 in the current object
            if (lab.labid === labId) {
                // Search for the string in the sections array
                const section = lab.sections.find(q => q.id === sectionId);

                // Set the input value based on the search result
                const desiredValue = section ? section.value : '';

                // Set the input value
                return desiredValue;

            }
        }

        // Return null or a default value if not found
        return null; // or return a default value of your choice
    };

    const countSectionProgress = (section) => {
        if ( section.subStepIds ) {
            let stepCount = section.subStepIds.length;
            let stepCountComplete = 0;
            section.subStepIds.map((step) => {
                let SectionUserData =  findStatusByLabAndSectionId(UserData, labID, step);
                if ( SectionUserData === "error") {
                    stepCountComplete++;
                }
            });
            return (stepCountComplete / stepCount) * 100;
        } else {
            let SectionUserData =  findStatusByLabAndSectionId(UserData, labID, section.id);
            if ( SectionUserData === "error") {
                return 100;
            }

        }
        return 0;
        
    }

    const findStatusByLabAndQuizId = (data, labId, sectionId) => {
        // Find the lab item with the matching labId
        // Iterate through each labid object
        for (const lab of data.data) {
            // Check if labid is 1 in the current object
            if (lab.labid === labId) {
                // Search for the string in the sections array
                const section = lab.quizzes?.find(q => q.id === sectionId);
                // Set the input value based on the search result
                if (section && section.value === true) {
                    return true;
                }

            }
        }

        // Return null or a default value if not found
        return false; // or return a default value of your choice
    };

    const setSectionState = (sectionID) => {
        setUserData((prevUserData) => {
            const labIndex = prevUserData.data.findIndex(
                (labDataItem) => labDataItem.labid === labID
            );

            if (labIndex === -1) {
                // If labID doesn't exist, add a new lab object
                const newLabDataItem = {
                    labid: labID,
                    sections: [{ id: sectionID, value: 'error' }],
                    questions: [{}],
                    quizzes: [{}],
                };

                return {
                    ...prevUserData,
                    data: [...prevUserData.data, newLabDataItem],
                };
            }

            const updatedUserData = {
                ...prevUserData,
                data: prevUserData?.data?.map((labDataItem) => {
                    if (labDataItem.labid === labID) {
                        // Check if the question with sectionID exists
                        const questionIndex = labDataItem.sections.findIndex(
                            (section) => section.id === sectionID
                        );

                        // If question with sectionID doesn't exist, add a new question
                        if (questionIndex === -1) {
                            return {
                                ...labDataItem,
                                sections: [
                                    ...labDataItem.sections,
                                    { id: sectionID, value: 'error' },
                                ],
                            };
                        }

                        // Update the existing question with a new value
                        return {
                            ...labDataItem,
                            sections: labDataItem?.sections?.map((section, index) => {
                                if (index === questionIndex) {
                                    return {
                                        ...section,
                                        value: 'error', // Convert string to boolean
                                        // You can update other properties as needed
                                    };
                                }
                                return section;
                            }),
                        };
                    }
                    return labDataItem;
                }),
            };

            return updatedUserData;
        });
    };

    const wizardLabFooter = (id) => (
        <LessonConsole
            terminalid={id}
            AwsInstance={AwsInstance}
        />
    );

    const findSectionAndChildren = (sections, targetId) => {
        for (const section of sections) {
            if (section.id === targetId) {
                return section;
            }

            if (section.children) {
                const foundInSection = findSectionAndChildren(section.children, targetId);
                if (foundInSection) {
                    foundInSection.parentid = section.id;
                    return foundInSection;
                }
            }
        }

        return null; // Return null if the targetId is not found in the current sections
    };

    const markSectionComplete = (targetStepID) => {
        const section = findSectionAndChildren(SectionData.sections, targetStepID);
        let sectionChildCount = 0;
        let completeChildCount = 0;

        const areAllChildContentComplete = (section) => {
            if (!section || !section.children) {
                return false;
            }

            for (const item of section.children) {
                sectionChildCount++;
                if (findStatusByLabAndSectionId(UserData, labID, item.id)) {
                    completeChildCount++;
                }
            }

            if (
                sectionChildCount &&
                completeChildCount &&
                completeChildCount === sectionChildCount
            ) {
                return true; // All child content is complete
            }

            return false;
        }


        if (
            section?.content &&
            !section.content?.some((item) => item.type === 'quiz') &&
            !section.content?.some((item) => item.type === 'aws')
        ) {
            setSectionState(section.id);
        } else if (
            section?.content?.some((item) => item.type === 'quiz') &&
            section.content.some((item) => findStatusByLabAndQuizId(UserData, labID, item.id))
        ) {
            setSectionState(section.id);
        } else if (
            section?.content?.some((item) => item.type === 'quiz') &&
            !section.content.some((item) => findStatusByLabAndQuizId(UserData, labID, item.id))
        ) {
            //do something here?
        }
        //set parent complete if all children are complete
        let parentSection = findSectionAndChildren(SectionData.sections, section.parentid)
        if (
            section?.content &&
            section.parentid &&
            areAllChildContentComplete(parentSection)
        ) {
            setSectionState(section.parentid);
        }

    };

    const nav = (isExpanded, steps, activeStep, goToStepByIndex) => {
        const getSubSteps = (subStepIds) => {
            return subStepIds.map((subStepId) => steps.find((step) => step.id === subStepId));
        }

        
        
        const hasActiveChildSteps = (step, activeStep) => {
            if (!step.subStepIds || step.subStepIds.length === 0) {
                // If the step has no child steps, return false
                return false;
            }

            // Check if any of the child step IDs match the active step ID
            return step.subStepIds.some(childId => childId === activeStep.id);
        }

        return (
            <WizardNav isExpanded={isExpanded}>
                <Panel className='mp-wizardHeader' style={{ padding: '20px' }}>
                    <Button variant='link' component="a" onClick={handleButtonClick}>
                        <Icon>
                            <AngleLeftIcon />
                        </Icon>
                        Back to Lab Exercises
                    </Button>
                    <Title headingLevel="h1" className='mp-wizardHeaderTitle'>
                        {LabsData.lessonName}
                    </Title>
                    {/* <Progress value={LabProgress} size={ProgressSize.sm} /> */}
                </Panel>
                {steps.map((step) => {
                    if (step.subStepIds && step.subStepIds.length > 0) {
                        let sectionProgress = countSectionProgress(step);

                        if ( sectionProgress > 99 ) {
                            step.status = "error";
                        }

                        return (
                            <WizardNavItem
                                key={`nav_${step.id}`}
                                id={step.id}
                                //content={step.name}
                                content={<span className='mp-completeIcon' style={{ color: 'var(--pf-v5-global--default-color--200)' }}>{step.screenname}<FontAwesomeIcon className='mp-competeIconFA' icon={faCircleCheck} /></span>}
                                isCurrent={activeStep.id === step.id || hasActiveChildSteps(step, activeStep)}
                                isDisabled={step.isDisabled}
                                stepIndex={step.index}
                                isExpandable={step.subStepIds}
                                status={step.status}
                            >
                                <Progress value={sectionProgress} size={ProgressSize.sm} measureLocation={ProgressMeasureLocation.none} />
                                {step.subStepIds && getSubSteps(step.subStepIds).map((subStep) => (
                                    <WizardNavItem
                                        key={`subnav_${subStep.id}`}
                                        id={subStep.id}
                                        content={<span className='mp-completeIcon' style={{ color: 'var(--pf-v5-global--default-color--200)' }}>{subStep.screenname}</span>}
                                        isCurrent={activeStep.id === subStep.id}
                                        isDisabled={subStep.isDisabled}
                                        stepIndex={subStep.index}
                                        onClick={() => goToStepByIndex(subStep.index)}
                                        status={subStep.status}
                                    />
                                ))}
                            </WizardNavItem>
                        );
                    } else if (step.screenname === "Finish" || step.screenname === "Lab Details" ) {
                        return <WizardNavItem
                            key={`nav_${step.id}`}
                            id={step.id}
                            //content={step.name}
                            content={<span className='mp-completeIcon' style={{ color: 'var(--pf-v5-global--default-color--200)' }}>{step.screenname}</span>}
                            isCurrent={activeStep.id === step.id || hasActiveChildSteps(step, activeStep)}
                            isDisabled={step.isDisabled}
                            stepIndex={step.index}
                            onClick={() => goToStepByIndex(step.index)}
                            status={step.status}
                        />
                    } else if (step.solo) {
                        return <WizardNavItem
                            key={`nav_${step.id}`}
                            id={step.id}
                            //content={step.name}
                            content={<span className='mp-completeIcon' style={{ color: 'var(--pf-v5-global--default-color--200)' }}>{step.screenname}</span>}
                            isCurrent={activeStep.id === step.id || hasActiveChildSteps(step, activeStep)}
                            isDisabled={step.isDisabled}
                            stepIndex={step.index}
                            onClick={() => goToStepByIndex(step.index)}
                            status={step.status}
                        >
                            <Progress value={countSectionProgress(step)} size={ProgressSize.sm} measureLocation={ProgressMeasureLocation.none} />
                        </WizardNavItem>
                    }
                })}

                {LabsData.includesAWS ? (
                    <CountdownTimer AwsInstance={AwsInstance} />
                ) : null}
            </WizardNav>
        );
    }

    const wizardLabStep = (section, index) => (
        <WizardStep
            key={`${section.id} ${section.name}`}
            id={section.id}
            name={`${section.id} ${section.name}`}
            screenname={section.name}
            solo={section.solo}
            status={findStatusByLabAndSectionId(UserData, labID, section.id)}
            footer={{
                isCancelHidden: true,
                isBackHidden: false,
                backButtonText: 'Previous',
                ...(section.last ? { onNext: () => navigate('/') } : {}),
                ...(section.last ? { nextButtonText: "Finish" } : {}),
                isNextDisabled:
                    section.content &&
                    section.content.some((item) => item.type === 'quiz') &&
                    !section.content.some((item) => findStatusByLabAndQuizId(UserData, labID, item.id))
            }}
            //navItem={{
            //  content: <span className='mp-completeIcon' style={{ color: 'var(--pf-v5-global--default-color--200)' }}>{section.name}</span>
            //}}
            steps={
                section.children?.map((section, index) => wizardLabStep(section, index))
            }
        >
            <LabWizardStep
                labID={labID}
                stepData={section}
                UserData={UserData}
                setUserData={setUserData}
                findStatusByLabAndQuizId={findStatusByLabAndQuizId}
                markSectionComplete={markSectionComplete}
                AwsInstance={AwsInstance}
            />
        </WizardStep>
    );

    return (
        <Panel
            className={LabsData.includesAWS ? (
                "mp-wizardAWS"
            ) : "mp-wizard"}
        >

            {LabsData.includesAWS && !AwsInstance.status ? (
                <AWSModal
                    userid={userid}
                    AwsInstance={AwsInstance}
                    setAwsInstance={setAwsInstance}
                />
            ) : null}
            {LabsData.includesAWS && AwsInstance.status ? (
                <AWSExtendModal
                    AwsInstance={AwsInstance}
                    setAwsInstance={setAwsInstance}
                />
            ) : null}
            <Wizard
                children={
                    SectionData?.sections?.map((section, index) => wizardLabStep(section, index))
                }
                nav={nav}
                onStepChange={onStepChange}
            />
        </Panel>
    );
}

export default LessonWizard;