import React, { useEffect, useState } from "react";
import fetchAPIData from "./GetData";

const GlobalUserData = ({ userid }) => {
    const [UserData, setUserData] = useState();

    useEffect(() => {
        const fetchAPIDataAsync = async () => {
            try {
                const response = await fetchAPIData({ userid: userid });
                
                setUserData(response);
            } catch (error) {
                // Handle errors if needed
            }
        };

        fetchAPIDataAsync();
    }, [userid]);

    return UserData;
}

export default GlobalUserData;