import React, { useEffect, useState } from "react";

const GlobalLessonData = () => {
    const [LessonData, setLessonData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_DATA_FILE);
                const data = await response.json();

                // Assuming data is an array in your case
                // TODO Slice this elsewhere const limitedData = labLimit !== undefined ? data.slice(1, labLimit + 1) : data;
                setLessonData(data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);

    return LessonData;
}

export default GlobalLessonData;