const fetchAPIData = async ({ userid }) => {
    try {
        const endpointurl = process.env.REACT_APP_URL
        const wstoken = process.env.REACT_APP_WSTOKEN;
        const moodlewsrestformat = process.env.REACT_APP_API_FORMAT;
        const wsfunction = 'local_mpirical_lab_show_user';

        const url = `${endpointurl}/webservice/rest/server.php?wstoken=${wstoken}&wsfunction=${wsfunction}&moodlewsrestformat=${moodlewsrestformat}&userid=${userid}`;
        const response = await fetch(url, { method: 'POST' });

        const data = await response.json();

        // Check if "data.progress" is a valid JSON
        try {
            const progressData = JSON.parse(data?.progress || "{\"data\":[]}"); // Return an empty object if data.progress is undefined

            return progressData;
        } catch (jsonError) {
            console.error('Invalid JSON in progress:', jsonError);
        }

        //return data;
    } catch (error) {
        console.error('Error fetching labs data:', error);
        throw error;
    }
};

export default fetchAPIData;