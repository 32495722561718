import React, {useState} from 'react';
import {
    Tabs,
    Tab,
    TabTitleText,
    TabAction,
    Popover,
    Button,
    Panel,
    Bullseye
} from '@patternfly/react-core';
import LabTerminal from '../classes/terminal.class';

export const SingleConsole = ( { terminalid, AwsInstance } ) => {
    const terminals = [];

    const openSingleTerminal = ( id ) => {
        id = new LabTerminal({
            container: `${terminalid}-terminal-single`,
            initialCommandTrigger: null,
            initialCommand: null,
            AWSHost: AwsInstance,
          });
          id.create(); // This should trigger componentDidMount
    
        return () => {
            id.destroy();
    
          };
    
      }

      return (
        <div style={{width:'100%', height:'100%'}}>
             <Panel id={`${terminalid}-terminal-single`}>
                <Bullseye id={`${terminalid}-terminal-button`} style={{width: '100%'}}>
                <Button onClick={() => openSingleTerminal(terminalid)}>Connect to Lab</Button>
                </Bullseye>
            </Panel>
        </div>
      )

}

export default SingleConsole;