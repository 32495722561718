import React from 'react';
import { Panel, PanelMain, PanelMainBody, Grid, GridItem, Avatar, Flex, FlexItem, Title, Bullseye } from '@patternfly/react-core';
import DashboardHeaderImage from "../../assets/dashboard-header.png";
import avatarPlaceholder from "../../assets/avatar.svg";

const DashboardHeader = () => {
    const storedProfilePicture = sessionStorage.getItem('globalProfilePicture');

    return (
        <Panel variant="raised" className="mp-dashboardHeader" style={{ backgroundImage: `linear-gradient(to right, rgba(93, 182, 230, 0.6), rgba(93, 182, 230, 0.6)), url(${DashboardHeaderImage})` }}>
            <Bullseye><Flex alignSelf={{ default: 'alignSelfCenter' }} >
                <FlexItem span={2}>
                    <Avatar
                        size="lg"
                        src={storedProfilePicture !== 'none' ? storedProfilePicture : avatarPlaceholder}
                        alt="Avatar"
                    />
                </FlexItem>
                <FlexItem span={10} className="mp-dashboardHeaderTitleWrap"><Title className="mp-dashboardHeaderTitle" headingLevel="h1">Welcome {sessionStorage.getItem('globalFirstName')} {sessionStorage.getItem('globalLastName')}</Title></FlexItem>
            </Flex>
            </Bullseye>
        </Panel>
    );
};

export default DashboardHeader;
